





















































































import { defineComponent, computed, useRouter } from "@nuxtjs/composition-api";
import { SfImage } from "@storefront-ui/vue";
import apichoice from "~/modules/fortytwo/dynamicyield/helper/dynamicyield";
import SkeletonLoader from "~/components/SkeletonLoader/index.vue";
import { Flicking } from "@egjs/vue-flicking";

export default defineComponent({
  name: "FortytwoShopByStyle",
  components: {
    SfImage,
    SkeletonLoader,
    Flicking,
  },
  props: {
    data: {
      type: Object,
      default: {},
      required: true,
    },
  },
  setup(props) {
    const { apidycall, loadingdy, dyresultdata } = apichoice();
    const dydecisionID = computed( ()=> props.data?.decisionId  || '',);
    const router = useRouter();
    const shopByStyleArray = computed(() => Array.isArray(props.data?.data) ? props.data?.data.sort((a, b) => a.order - b.order) : props.data?.data ?? []);
    const currentURL = window.location.href;
    
    const addEngagements =  (imagelink: string, decisionID: string, variationsId: number) => {
       apidycall(decisionID,"CLICK","Engagements", [variationsId],"",currentURL,false);  
    };

    return {
      shopByStyleArray,
      dydecisionID,
      addEngagements
    };
  },
});
